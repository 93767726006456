import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {IpLocationService} from './IpLocation.service';
import {NgCookieService} from './ng-cookie.service';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    private readonly _DEFAULT_LOCALE = 'en';
    private readonly _DEFAULT_LOCALE_CURRENCY = 'usd';

    private readonly _RTL_LAYOUT_DIRECTIONS_LOCALES = [
        'he',
        'ar',
    ];

    private readonly _COUNTRY_CODE_TO_LOCALE_LOOKUP = new Map<string, string>([
        ['il', 'he'],
    ]);

    private readonly _CURRENCY_CODE_TO_LOOKUP = [
        'brl',
        'eur',
        'ils',
        'usd',
        'zar',
        'vnd',
        'uah',
        'try',
        'thb',
        'sar',
        'qar',
        'php',
        'pen',
        'myr',
        'mxn',
        'krw',
        'inr',
        'gbp',
        'crc',
        'cop',
        'cny',
        'clp',
        'cad',
        'aud',
        'ars',
        'aed'
    ];


    private readonly _LOCALE_COOKIE_KEY = "locale";
    private readonly _LOCALE_CURRENCY_COOKIE_KEY = "locale_currency";

    locale: string;
    localCurrency: string;
    localePromise: Promise<string[]>
    localeCurrencyPromise: Promise<string[]>

    constructor(
        private locationService: IpLocationService,
        private cookieService: NgCookieService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.localePromise = null;
        this.localeCurrencyPromise = null;
    }

    /**
     * Get the locale based on the user's location
     * @returns An array of strings containing the found locale and the fallback locale
     * in the following form: [{FOUND_LOCALE}, {DEFAULT_LOCALE}]
     */
    getLocale = (reload ?: boolean) => {
        if (!this.localePromise || reload) {
            this.localePromise = this._getLocale();
        }
        return this.localePromise;
    }


    getLocaleCurrency = (reload ?: boolean) => {
        if (!this.localeCurrencyPromise || reload) {
            this.localeCurrencyPromise = this._getLocaleCurrency();
        }
        return this.localeCurrencyPromise;
    }

    /**
     * used in pricing , to change pricing currency by routing query params
     */
    setLocaleCurrency = (currency : string) => {
        this.saveAndCheckCurrency(currency)
    }

    private _getLocale = async (): Promise<string[]> => {
        if (this.locale) {
            return [this.locale, this._DEFAULT_LOCALE];
        }

        const localeCookie = this.cookieService.getCookie(this._LOCALE_COOKIE_KEY);
        if (localeCookie) {
            this.locale = localeCookie;
            return [this.locale, this._DEFAULT_LOCALE];
        }

        try {
            await this._getIpAndLocation();

        } catch (error) {
            this.locale = this._DEFAULT_LOCALE;
            throw "Failed to find your location";

        } finally {
            return [this.locale, this._DEFAULT_LOCALE];
        }
    }


    /***
     * return currency by location store only in cookie ,used in pricing
     */
    private _getLocaleCurrency = async (): Promise<string[]> => {
        const localeCurrencyCookie = this.cookieService.getCookie(this._LOCALE_CURRENCY_COOKIE_KEY);
        if (localeCurrencyCookie) { // in case we already have one saved it usually not changes
            this.localCurrency = localeCurrencyCookie;
            return [localeCurrencyCookie, this._DEFAULT_LOCALE_CURRENCY];
        }
        try { // in case it first init
            await this._getIpAndLocation();
        } catch (error) {
            this.localCurrency = this._DEFAULT_LOCALE_CURRENCY;
            throw "Failed to find your location";

        } finally {
            return [this.localCurrency, this._DEFAULT_LOCALE_CURRENCY];
        }
    }


    private _getIpAndLocation = async () => {
        this.locale = this.getTwoLettersLangCode();
        this.cookieService.setCookie(this._LOCALE_COOKIE_KEY, this.locale);
        if (isPlatformBrowser(this.platformId)) {
            const location = await firstValueFrom(this.locationService.getLocation());
            if (location) {
                this.saveAndCheckCurrency(location.currency.toLowerCase())
            }
        }
    }

    /**
     *  check currency or uses fallback and stores it in cookie
     */
    private saveAndCheckCurrency = (currency : string) => {
        this.localCurrency = this._CURRENCY_CODE_TO_LOOKUP.includes(currency) ? currency : this._DEFAULT_LOCALE_CURRENCY;
        this.cookieService.setCookie(this._LOCALE_CURRENCY_COOKIE_KEY, this.localCurrency);
    }


    /**
     * Changes the current locale
     * @param locale The locale to set
     */
    setLocale = async (locale: string): Promise<void> => {
        this.cookieService.setCookie(this._LOCALE_COOKIE_KEY, locale);
    }

    /**
     * Returns a boolean indicating whether the locale supports left-to-right or right-to-left layout.
     * @returns True if the locale supports rtl layout, False otherwise
     */
    isRtlLayout = () => {
        return this._RTL_LAYOUT_DIRECTIONS_LOCALES.includes(this.locale);
    }

    private getTwoLettersLangCode() {
        let lang;
        if (isPlatformBrowser(this.platformId)) {
            lang = window.navigator['userLanguage'] || window.navigator.language || this._DEFAULT_LOCALE;
        } else {
            lang = this._DEFAULT_LOCALE;
        }
        const langParts = lang.split("-");
        return (langParts?.length ?? 0) > 0 ? langParts[0] : lang;
    }
}
