import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map, Observable, throwError} from "rxjs";
import 'country-to-currency';
import countryToCurrency from "country-to-currency";
import {catchError} from "rxjs/operators";

export interface IpAddress {
    ip: string;
}

export interface Location {
    ip: string,
    countryCode: string;
    countryName: string;
    currency: string;
}

@Injectable({
    providedIn: 'root'
})
export class IpLocationService {

    constructor(private http: HttpClient) {
    }

    getLocation(): Observable<Location> {
        try {
            return this.http.get('/ip').pipe(
                map((json: any) => ({
                    ip: json.ip,
                    countryCode: json.countryCode,
                    countryName: json.countryName,
                    currency: countryToCurrency[json.countryCode]
                })),
                catchError((error: any) => {
                    return throwError('Unable to fetch location data.');
                })
            );
        } catch (error) {
            return throwError('Unable to fetch location data.');
        }
    }

}
